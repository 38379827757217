<template>
  <div class="about-components-news-wrapper">
    <div
      v-if="isLoad && newsList.length"
    >
      <div class="title">
        {{ $t('newsCenter') }}
      </div>
      <div class="news-list">
        <div class="news-list-content">
          <div class="news-list-content-swiper">
            <template
              v-for="(item, index) in computedList"
            >
              <div
                :key="index"
                class="news-slide"
                @click="newsClick(item)"
              > 
                <div
                  class="news-slide-img"
                >
                  <img
                    :src="item.cover"
                  />
                </div>
                <div
                  class="news-slide-des"
                >
                  <div>{{ item.title }}</div>
                  <div>{{ time(item.show_time) }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime, goGet } from '@/utils'
export default {
  name: 'AboutNews',
  data() {
    return {
      isLoad: false,
      newsList: [],
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    computedList() {
      return this.newsList.filter(list => list.lang === this.$i18n.locale)
    },
  },
  methods: {
    getList() {
      goGet('/ewinlight/article/list').then(res => {
        this.isLoad = true
        this.newsList = res.data.map(list => {
          list.cover = list.cover.replace('http://192.168.2.245:1500', process.env.VUE_APP_API)
          return list
        })
      })
    },
    time(date) {
      return formatTime('format', date)
    },
    newsClick(item) {
      this.$router.push('/article/' + item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.about-components-news-wrapper {
  padding: 20px;
  padding-bottom: 35px;
  background: rgba(245, 246, 247, 1);
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #222222;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .news-list-content-swiper {
    .news-slide {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      overflow: hidden;
      &-img {
        width: 100%;
        height: 188px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      &-des {
        padding: 10px 20px;
        padding-bottom: 20px;
        font-size: 13px;
        color: #999999;
        line-height: 20px;
        & > div {
          &:nth-child(1) {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 4px;
            color: rgba(34, 34, 34, 1);
          }
        }
      }
    }
  }
}
</style>